html,
body {
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
}

body {
  width: 100vw;
  overflow-x: hidden;
}

.refurbishment-details-page {
  background-image: url("../images/refurb-1.jpg");
  background-repeat: no-repeat;
  position: relative;
  background-size: cover;
  width: 100vw;
  height: 70vh;

  &__container {
    padding-top: 70vh;
  }

  &__refurbishment-details-page-header {
    position: relative;
    top: 45vh;
    left: 20vw;
    & h1,
    h2,
    h3,
    h4,
    h5,
    p {
      color: white;
    }
  }

  &__gallery {
    padding: 3rem 0;
  }
}
